import { createContext, useContext, useEffect, useState } from 'react';

interface ILocationContext {
  location: any;
}

export const LocationContext = createContext<ILocationContext | null>(null);

function isSameLocation(loc1, loc2) {
  if (loc1.pathname.replace(/#$/, '') === loc2.pathname.replace(/#$/, '')) {
    return loc1.query === loc2.query;
  }
  return false;
}

export function LocationProvider({ history, children }) {
  const [location, setLocation] = useState(history.getCurrentLocation());

  useEffect(() => {
    const unlisten = history.listen((newLocation, _action) => {
      if (!isSameLocation(location, newLocation)) {
        setLocation(newLocation);
      }
    });

    return unlisten;
  }, []);

  return (
    <LocationContext.Provider value={{ location }}>
      {children}
    </LocationContext.Provider>
  );
}

export const useLocation = () => {
  const ctx = useContext(LocationContext);
  return ctx.location;
};
