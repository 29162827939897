import { useQuery, useQueryClient } from '@tanstack/react-query';
import { User } from '../types';
import { axiosInstance } from '../utils/axiosInstance';

const SESSION_CHECK_INTERVAL = 1000 * 60 * 60; // 1 hour
const currentUserEndpoint = '/api/v2/users/current.json';
const sessionExpiredEndpoint = '/session-expired';
const sessionCheckEndpoint = '/session-check';

export const EMPTY_USER = {
  id: null,
  logged_in: false,
  username: '',
  email: '',
  display_login: '',
  shop_name: '',
  internal_corporate: '',
  can_print: false,
  can_bookmark: false,
  account_number: null,
  feature_set: '',
  trial: false,
  subscriptions: [],
} as User;

async function getCurrentUser(): Promise<User> {
  return axiosInstance.get(currentUserEndpoint).then((res) => res.data);
}

interface UseUser {
  isLoading: boolean;
  currentUser: User | null;
  updateUser: (user: User) => void;
  clearUser: () => void;
}

export function useUser(): UseUser {
  const queryClient = useQueryClient();

  const { data: currentUser, isLoading } = useQuery({
    queryKey: ['currentUser'],
    queryFn: () => getCurrentUser(),
    staleTime: 0,
    initialData: EMPTY_USER,
    // onSuccess: (user: User | null) => {
    //   if (user.logged_in) {
    //     console.log('Got logged in user', user);
    //     //   clearStoredUser();
    //   } else {
    //     console.log('User logged out');
    //     //   setStoredUser(received);
    //   }
    // },
  });

  function clearUser() {
    queryClient.invalidateQueries({
      queryKey: ['currentUser'],
    });
  }

  function updateUser(user: User) {
    console.log('TODO: Implement me');
    return;
  }

  return { isLoading, currentUser, updateUser, clearUser };
}

const redirectIfExpired = (isExpired) => {
  const currPath = window.location.pathname;
  if (isExpired) {
    // window.location = `${sessionExpiredEndpoint}?intent=${currPath}`;
    console.warn('SHOULD BE REDIRECTED TO LOGOUT');
  }
};

const sessionExpired = () =>
  fetch(sessionCheckEndpoint, { method: 'HEAD', credentials: 'same-origin' })
    .then((resp) => resp.status === 401)
    .then(redirectIfExpired)
    .catch((err) => {
      throw err;
    });

export const checkForSessionTimeout = (interval) => {
  window.setInterval(() => sessionExpired(), interval);
};
