import axios, { AxiosRequestConfig } from 'axios';

// import { User } from '../../../shared/types';
// import { baseUrl } from './constants';

// export function getJWTHeader(user: User): Record<string, string> {
//   return { Authorization: `Bearer ${user.token}` };
// }

export const TEST_BASE_URL = 'http://127.0.0.1:6006';

const config: AxiosRequestConfig =
  process.env.NODE_ENV === 'test'
    ? {
        baseURL: TEST_BASE_URL,
      }
    : {};
export const axiosInstance = axios.create(config);
