/* globals Whitelabel */
import React, { useState, lazy, Suspense } from 'react';

import { useLoggedOutTracking } from '../../hooks/tealium';

import quicksellIconOne from './images/icon-one.svg';
import quicksellIconTwo from './images/icon-two.svg';
import quicksellIconThree from './images/icon-three.svg';
import frontPageCar from './images/buickregal.jpg';

import macBook from './images/macbook-blank.png';
import userVideo from './images/moto-demo.mp4';

const Modal = lazy(() => import('../modal.js'));
const ContactForm = lazy(() => import('./ContactForm'));

const Header = ({ contactOnClick, signInLink }) => {
  const { trackEvent } = useLoggedOutTracking();

  const requestACallClicked = () => {
    trackEvent('front page', 'request call button', 'clicked');
    contactOnClick();
  };

  return (
    <div className="frontpage-header-container">
      <div className="page-width">
        <div id="page-head">
          <div className="">
            <div className="header-container">
              <div className="header-cell">
                <h1>
                  <a className={`${Whitelabel.key}`} href="/">
                    {Whitelabel.name}
                  </a>
                </h1>
              </div>
              <div className="header-cell">
                <nav>
                  <ul>
                    <li>
                      <a
                        className="button button-primary"
                        onClick={requestACallClicked}
                      >
                        Request a Call
                      </a>
                    </li>
                    <li>
                      <div
                        dangerouslySetInnerHTML={{ __html: signInLink }}
                        onClick={() =>
                          trackEvent('front page', 'login button', 'clicked')
                        }
                      />
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Frontpage = ({
  signUp,
  leadCreationLink,
  leadCreationToken,
  signInLink,
}) => {
  const [visible, setVisible] = useState(signUp);
  const { trackEvent } = useLoggedOutTracking();

  return (
    <div className="frontpage-container">
      <Header contactOnClick={() => setVisible(true)} signInLink={signInLink} />
      <div id="rafrontpage">
        <Suspense fallback={<h1>Loading</h1>}>
          <Modal visible={visible} onClose={() => setVisible(false)}>
            <ContactForm
              leadCreationLink={leadCreationLink}
              leadCreationToken={leadCreationToken}
            />
          </Modal>
        </Suspense>

        {/* {Whitelabel.label_id === 'driverside' && <DriversideFrontpage/>} */}
        {Whitelabel.label_id === 'motologic' && (
          <>
            <section>
              <div className="hero-image">
                <h2>Find the Right Information - Fast</h2>
                <p>
                  {Whitelabel.name} gives you quick access to unedited OEM
                  repair and diagnostic content along with the highest quality
                  aftermarket service info to get the job done right.
                </p>
              </div>
            </section>
            <div className="spacer">
              <img
                className="computer-image"
                src={macBook}
                alt="laptop showing the application"
                loading="eager"
              />
              <video
                className="video-screen"
                autoPlay={true}
                loop={true}
                muted={true}
              >
                <track
                  label="English"
                  kind="captions"
                  srcLang="en"
                  default={true}
                />
                <source type="video/mp4" src={userVideo} />
                Your browser does not support the video tag.
              </video>
            </div>
            <section className="callouts">
              <div>
                <div className="quicksell-group border-bottom">
                  <div className="quicksell-item width-25">
                    <img
                      src={quicksellIconOne}
                      className="quicksell-icon"
                      alt="Icon of the first quicksell"
                      loading="lazy"
                    />
                    <h3 className="medium-header">Simplicity You Expect</h3>
                    <p>
                      {Whitelabel.name} delivers consistency with fast search
                      results and simple navigation so you can easily find the
                      info you need to get the job done, every time.
                    </p>
                  </div>
                  <div className="quicksell-item width-25">
                    <img
                      src={quicksellIconTwo}
                      className="quicksell-icon"
                      alt="Icon of the first quicksell"
                      loading="lazy"
                    />
                    <h3 className="medium-header">Updated Coverage You Need</h3>
                    <p>
                      Get the most current information for 99% of vehicle
                      manufacturers in operation. We strive to give you the
                      latest available late model coverage faster than anyone in
                      the industry.
                    </p>
                  </div>
                  <div className="quicksell-item width-25">
                    <img
                      src={quicksellIconThree}
                      className="quicksell-icon"
                      alt="Icon of the first quicksell"
                      loading="lazy"
                    />
                    <h3 className="medium-header">A Resource You Can Trust</h3>
                    <p>
                      You never have to worry about OEM info being edited or
                      deleted with {Whitelabel.name}. You can count on the OEM
                      information being 100% complete just as the manufacturer
                      intended.
                    </p>
                  </div>
                </div>
                <div className="quicksell-group-two ">
                  <div className="quicksell-item width-25 special-case">
                    <h3 className="medium-header">Take a Test Drive</h3>
                    <p>
                      See the difference today with {Whitelabel.name}&#39;s
                      advanced search engine technology and high quality
                      information and graphics.
                    </p>
                    <a
                      className="button button-primary"
                      href="/car/2014_buick_regal_100300438"
                      onClick={() =>
                        trackEvent(
                          'front page',
                          'explore car button',
                          'clicked'
                        )
                      }
                    >
                      Explore a Test Car
                    </a>
                  </div>
                  <div className="quicksell-item width-50 frontpage-car">
                    <img src={frontPageCar} alt="sample car" loading="lazy" />
                  </div>
                </div>
              </div>
            </section>
          </>
        )}
        <section className="footer">
          <div className="links">
            <p>
              Change Language:
              <a
                href="/?locale=en"
                data-turbolinks="false"
                onClick={() =>
                  trackEvent('front page', 'english link', 'clicked')
                }
              >
                English
              </a>
              <a
                href="/?locale=fr"
                data-turbolinks="false"
                onClick={() =>
                  trackEvent('front page', 'french link', 'clicked')
                }
              >
                Français
              </a>
            </p>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Frontpage;
