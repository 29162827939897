/* globals Whitelabel */
import React, { lazy, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import Frontpage from 'Components/Frontpage/Frontpage';

import '../assets/stylesheets/frontpage.css.scss';

const FrenchFrontpage = lazy(() =>
  import('Components/Frontpage/FrenchFrontpage')
);
const DriverSideFrontpage = lazy(() => import('DriverSide/Frontpage'));

document.addEventListener('DOMContentLoaded', () => {
  const app = document.getElementById('frontpage');
  const props = JSON.parse(app.getAttribute('state'));

  const Front = () => {
    let Component = Frontpage;

    if (props.locale === 'fr') Component = FrenchFrontpage;
    if (Whitelabel.label_id === 'driverside') Component = DriverSideFrontpage;

    return (
      <Suspense fallback={<div />}>
        <Component {...props} />
      </Suspense>
    );
  };

  const root = createRoot(app);
  root.render(<Front {...props} />);
});
